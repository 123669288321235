import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { ToastContainer } from 'react-toastify';

import { CustomHead } from '@/components/shared';
import { UserProvider } from '../HOC';

import 'react-toastify/dist/ReactToastify.css';
import '../../styles/globals.css';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <div>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GA_TOKEN}');
                      `}
      </Script>
      <UserProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          className="toaster"
        />
        <CustomHead />
        <Component {...pageProps} />
      </UserProvider>
    </div>
  );
}

export default MyApp;
