import SocialApple from '@/components/shared/Icons/iconsList/social-apple.svg';
import SocialFacebook from '@/components/shared/Icons/iconsList/social-facebook.svg';
import SocialGoogle from '@/components/shared/Icons/iconsList/social-google.svg';
import SocialTrufusion from '@/components/shared/Icons/iconsList/social-trufusion.svg';
import SocialTvFit from '@/components/shared/Icons/iconsList/social-tvfit.svg';

import Checked from '@/components/shared/Icons/iconsList/checked.svg';
import ArrowNext from '@/components/shared/Icons/iconsList/arrow-next.svg';
import ArrowPrevious from '@/components/shared/Icons/iconsList/arrow-previous.svg';

import DropdownIndicator from '@/components/shared/Icons/iconsList/dropdown/indicator.svg';

import Search from '@/components/shared/Icons/iconsList/search.svg';
import AccountCircle from '@/components/shared/Icons/iconsList/account-circle.svg';

import VideosPaginationArrowNext from '@/components/shared/Icons/iconsList/videos-pagination-arrow-next.svg';

import Favorite from '@/components/shared/Icons/iconsList/favorite.svg';
import NotFavorite from '@/components/shared/Icons/iconsList/not-favorite.svg';

import RecommendationChecked from '@/components/shared/Icons/iconsList/home/recommendations/checked.svg';

import PlannerAdd from '@/components/shared/Icons/iconsList/home/planner/add.svg';

import VideoClosedCaption from '@/components/shared/Icons/iconsList/video/closed-caption.svg';
import VideoForward from '@/components/shared/Icons/iconsList/video/forward.svg';
import VideoFullScreen from '@/components/shared/Icons/iconsList/video/full-screen.svg';
import VideoPlay from '@/components/shared/Icons/iconsList/video/play.svg';
import VideoReplay from '@/components/shared/Icons/iconsList/video/replay.svg';
import VideoSettings from '@/components/shared/Icons/iconsList/video/settings.svg';
import VideoVolumeUp from '@/components/shared/Icons/iconsList/video/volume-up.svg';
import VideoPause from '@/components/shared/Icons/iconsList/video/pause.svg';

type VideoIcon =
  | 'videoClosedCaption'
  | 'videoForward'
  | 'videoFullScreen'
  | 'videoPlay'
  | 'videoReplay'
  | 'videoSettings'
  | 'videoVolumeUp'
  | 'videoPause';

type socialIcon =
  | 'socialApple'
  | 'socialFacebook'
  | 'socialGoogle'
  | 'socialTrufusion'
  | 'socialTvFit';

type dropdownIcons = 'dropdownIndicator';

type otherIcons =
  | 'checked'
  | 'arrowNext'
  | 'arrowPrevious'
  | 'search'
  | 'accountCircle'
  | 'videosPaginationArrowNext'
  | 'favorite'
  | 'notFavorite'
  | 'recommendationsChecked'
  | 'plannerAdd';

export type iconType = socialIcon | otherIcons | dropdownIcons | VideoIcon;

export const iconList = {
  socialApple: SocialApple,
  socialFacebook: SocialFacebook,
  socialGoogle: SocialGoogle,
  socialTrufusion: SocialTrufusion,
  socialTvFit: SocialTvFit,
  checked: Checked,
  arrowNext: ArrowNext,
  arrowPrevious: ArrowPrevious,
  dropdownIndicator: DropdownIndicator,
  search: Search,
  accountCircle: AccountCircle,
  videosPaginationArrowNext: VideosPaginationArrowNext,
  favorite: Favorite,
  notFavorite: NotFavorite,
  recommendationsChecked: RecommendationChecked,
  plannerAdd: PlannerAdd,
  videoClosedCaption: VideoClosedCaption,
  videoForward: VideoForward,
  videoFullScreen: VideoFullScreen,
  videoPlay: VideoPlay,
  videoReplay: VideoReplay,
  videoSettings: VideoSettings,
  videoVolumeUp: VideoVolumeUp,
  videoPause: VideoPause,
};
