import SvgGeorgesStPierreLogo from './SvgGeorgesStPierreLogo';
import { SvgSpeakerphoneIcon } from './SvgSpeakerphoneIcon';
import { SvgCircleArrowDown } from './SvgCircleArrowDown';
import SvgRadioUnchecked from './SvgRadioUncheckedSvg';
import SvgCheckboxChecked from './SvgCheckboxChecked';
import { SvgEnvelopeIcon } from './SvgEnvelopeIcon';
import SvgBurgerMenuIcon from './SvgBurgerMenuIcon';
import SvgRadioChecked from './SvgRadioChecked';
import TruconnectLogo from './TruconnectLogo';
import RushfitTwoLogo from './RushfitTwoLogo';
import SvgNumberThree from './SvgNumberThree';
import SvgCreditCard from './SvgCreditCard';
import SvgNumberOne from './SvgNumberOne';
import SvgNumberTwo from './SvgNumberTwo';
import SvgUserIcon from './SvgUserIcon';
import SvgMenuIcon from './SvgMenuIcon';
import SvgPlayIcon from './SvgPlayIcon';
import SvgStripeIcon from './SvgStripe';
import SvgPayPal from './SvgPayPall';
import SvgXIcon from './SvgXIcon';
import SvgArrow from './SvgArrow';

export {
  SvgGeorgesStPierreLogo,
  SvgSpeakerphoneIcon,
  SvgCircleArrowDown,
  SvgCheckboxChecked,
  SvgBurgerMenuIcon,
  SvgRadioUnchecked,
  SvgRadioChecked,
  SvgEnvelopeIcon,
  TruconnectLogo,
  RushfitTwoLogo,
  SvgNumberThree,
  SvgStripeIcon,
  SvgCreditCard,
  SvgNumberTwo,
  SvgNumberOne,
  SvgUserIcon,
  SvgMenuIcon,
  SvgPlayIcon,
  SvgPayPal,
  SvgArrow,
  SvgXIcon,
};
