import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const noAuthAxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_HOST,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'App-Platform': 'Web',
  },
});

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_HOST,
  headers: {
    ['Accept']: 'application/json',
    ['Content-Type']: 'application/json',
    ['App-Platform']: 'Web',
  },
});

const requestHandler = (request: InternalAxiosRequestConfig) => {
  const token = Cookies.get('token');

  if (!request?.headers?.Authorization && typeof window !== 'undefined') {
    if (request.headers) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  return request;
};

const responseHandler = (response: AxiosResponse) => response;

const errorHandler = (error: { response: AxiosResponse }) => {
  if (error?.response?.status === 403) {
    toast.error('Please purchase a pack to proceed', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.error(`${error?.response?.data?.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosInstance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;
