import classNames from 'classnames';

const BUTTON_VARIANTS = {
  primary: 'bg-brand-primary hover:bg-brand-primaryHover',
  secondary:
    'border-[1.5px] border-brand-primary hover:bg-brand-primaryHover hover:border-brand-primaryHover',
};

const BUTTON_SIZES = {
  small: 'h-[27px] text-sm font-normal',
  medium: 'h-10 min-w-[130px]',
  large: 'h-[48px]',
};

interface ButtonProps {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
}

const Button = ({
  children,
  variant = 'primary',
  size = 'medium',
  className,
  disabled,
  type = 'button',
  onClick,
}: ButtonProps) => {
  const buttonVariant = BUTTON_VARIANTS[variant] ?? 'bg-brand-primary hover:bg-brand-primaryHover';
  const buttonSize = BUTTON_SIZES[size] ?? 'h-10 min-w-[130px]';

  return (
    <button
      className={classNames(
        'flex cursor-pointer items-center justify-center whitespace-nowrap rounded px-6 font-stag text-base font-semibold text-neutrals-white transition duration-300 disabled:bg-neutrals-mid',
        buttonVariant,
        buttonSize,
        className
      )}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
