import Link from 'next/link';
import { PAGE } from '@/router/routes';
import { SvgSpeakerphoneIcon } from '@/components/svg';
import { Button } from '@/components/shared/Buttons';

export const BlackFridayBanner = () => (
  <div className="flex flex-col items-start justify-between gap-2 gap-x-6 bg-indigo-600 px-6 py-2.5 font-stag text-base font-medium sm:flex-row sm:items-center md:px-12">
    <div className="flex items-center gap-2 text-sm uppercase tracking-wide text-white">
      <span className="rounded-lg bg-indigo-800 p-2">
        <SvgSpeakerphoneIcon />
      </span>
      <span>Limited time only! 50% off SELECTED PRODUCTS</span>
    </div>
    <div className="w-full sm:w-min">
      <Link href={PAGE.OPTIONS_SIGNUP} passHref>
        <div>
          <Button className="w-full bg-white text-indigo-600 hover:!bg-white">Get Started</Button>
        </div>
      </Link>
    </div>
  </div>
);
