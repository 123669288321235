import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import Icon from '@/components/shared/Icons/Icon';
import { iconType } from '@/types/components/icon.type';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  icon: iconType;
  onClick: () => void;
}

const IconButton = ({ onClick = () => {}, className = '', icon, ...props }: ButtonProps) => (
  <button
    className={classNames(
      'rounded-full flex items-center justify-center bg-brand-primary',
      className
    )}
    onClick={onClick}
    {...props}
  >
    <Icon name={icon} />
  </button>
);

export default IconButton;
