import classNames from 'classnames';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

interface PaymentButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
  className?: string;
}

const PaymentButton = ({ children, className = '', ...rest }: PaymentButtonProps) => (
  <button
    className={classNames(
      'flex h-[50px] items-center justify-center rounded bg-brand-primary font-stag text-base font-semibold outline-none duration-300 ease-in hover:bg-brand-primaryHover disabled:opacity-30',
      className
    )}
    {...rest}
  >
    {children}
  </button>
);
export default PaymentButton;
