import { useMemo } from 'react';
import classNames from 'classnames';
import { ButtonType } from '@/types/components/button.type';

interface LandingButtonProps {
  text: string;
  type: 'button' | 'submit' | 'reset' | undefined;
  buttonType: ButtonType;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const LandingButton = ({
  text,
  type,
  buttonType,
  className,
  disabled,
  onClick,
}: LandingButtonProps) => {
  const classes =
    'p-2 min-w-[130px] rounded text-neutrals-white text-base font-medium whitespace-nowrap cursor-pointer';

  const buttonTypeClassnames = useMemo(() => {
    if (buttonType === ButtonType.PRIMARY) {
      return `${classes} bg-landing-redMedium`;
    }
    return `${classes} bg-landing-redDark`;
  }, [buttonType]);

  return (
    <button
      className={classNames(`${buttonTypeClassnames} ${className}`)}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default LandingButton;
