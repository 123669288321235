export const ENDPOINTS = {
  AUTH: {
    PASSWORD_FORGOT: '/api/auth/passwords/forgot',
    PASSWORD_UPDATE: '/api/auth/passwords/set',
    PASSWORD_CHANGE: '/api/auth/passwords/change',

    EMAIL_SIGNUP: '/api/auth/register',
    EMAIL_SIGNIN: '/api/auth/login',

    GET_USER_BY_MAGIC_LINK_HASH: '/api/auth/login/ml/verify',
    MAGIC_LINK_LOGIN: '/api/auth/login/ml',
  },

  USER: {
    SETTINGS: '/api/settings',
    PROFILE: '/api/profile',
  },

  WORKOUTS: {
    WORKOUT: (workoutId: number | string) => `/api/workouts/${workoutId}`,
    WORKOUT_PROGRESS: '/api/workout-progresses',
  },

  PROGRAMMES: '/api/programmes/',

  COUNTRY_CODE: '/api/country-code',

  PRODUCTS: '/api/products',
  PURCHASES: '/api/purchases/invoice',
  PURCHASES_V2: '/api/purchases/payment-intent',
  HOME_HEADER: '/api/home-header',

  NEWS_SUBSCRIPTION: '/api/news-subscription',
  GET_IN_TOUCH: '/api/get-in-touch',
};
