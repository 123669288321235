export enum ButtonType {
  PRIMARY,
  SECONDARY,
}

export enum ButtonSize {
  SMALL,
  MEDIUM,
  LARGE,
}
