import { useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';

import IUser from '@/types/User';

import { ENDPOINTS } from '@/constants/endpoints.const';
import axiosInstance from '@/utils/axios';

const useAuth = () => {
  const [user, changeUser] = useState<IUser | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const logOut = useCallback(() => {
    changeUser(null);
    Cookies.remove('token');
  }, []);

  useEffect(() => {
    if (Cookies.get('token')) {
      const fetchUser = async () => {
        setIsFetching(true);

        try {
          const { data: user } = await axiosInstance.get(ENDPOINTS.USER.PROFILE);

          changeUser(user.data);
        } catch {
          logOut();
        } finally {
          setIsFetching(false);
        }
      };

      fetchUser();
    } else {
      setIsFetching(false);
    }
  }, []);

  const setUser = (user: IUser | null) => {
    changeUser(user);
  };

  return useMemo(
    () => ({
      user,
      setUser,
      logOut,
      isFetching,
    }),
    [isFetching, user, logOut]
  );
};

export default useAuth;
