import { SVGProps } from 'react';

const SvgBurgerMenuIcon = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.66683 10.667C5.93045 10.667 5.3335 11.2639 5.3335 12.0003C5.3335 12.7367 5.93045 13.3337 6.66683 13.3337H25.3335C26.0699 13.3337 26.6668 12.7367 26.6668 12.0003C26.6668 11.2639 26.0699 10.667 25.3335 10.667H6.66683Z"
      fill="white"
    />
    <path
      d="M6.66683 18.667C5.93045 18.667 5.3335 19.2639 5.3335 20.0003C5.3335 20.7367 5.93045 21.3337 6.66683 21.3337H25.3335C26.0699 21.3337 26.6668 20.7367 26.6668 20.0003C26.6668 19.2639 26.0699 18.667 25.3335 18.667H6.66683Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default SvgBurgerMenuIcon;
