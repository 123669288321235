import classNames from 'classnames';
import { SvgCheckboxChecked } from '@/components/svg';
import { useMemo } from 'react';

const Checkbox = ({
  checked,
  onToggle,
  disabled = false,
}: {
  checked: boolean;
  onToggle: () => void;
  disabled: boolean;
}) => {
  const classes = useMemo(
    () =>
      classNames(
        'mr-4 flex max-h-[18.5px] min-h-[18.5px] min-w-[18.5px] max-w-[18.5px] items-center justify-center rounded outline-none duration-200 ease-in',
        { 'border-brand-primary bg-brand-primary': checked },
        { 'bg-brand-primary': !checked },
        { 'opacity-25': disabled }
      ),
    [checked, disabled]
  );

  return (
    <button type="button" className={classes} onClick={onToggle} disabled={disabled}>
      {checked && <SvgCheckboxChecked className="h-2 w-3" />}
    </button>
  );
};

export default Checkbox;
