import { SVGProps } from 'react';

const SvgCheckboxChecked = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={13}
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.834 10.12L2.408 6.696a.984.984 0 10-1.393 1.391l4.128 4.125a.984.984 0 001.392 0l10.448-10.44A.984.984 0 1015.591.38l-9.757 9.74z"
      fill="#fff"
    />
  </svg>
);

export default SvgCheckboxChecked;
