import { SVGProps } from 'react';

const SvgRadioUnchecked = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="14" cy="14" r="14" fill="#F7F8F9" />
  </svg>
);

export default SvgRadioUnchecked;
