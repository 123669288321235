import Image from 'next/image';

const facebookIcon = '/icons/social-media-icons-facebook.svg';
const googleIcon = '/icons/google-g-logo.svg';
const appleIcon = '/icons/apple-logo-black.svg';
const logoSource = '/icons/logo.svg';
const blackLogo = '/icons/black-logo.svg';
const truconnectHeartIcon = '/icons/group.svg';
const truconnectRectangleIcon = '/icons/mark.svg';
const truconnectBy = '/images/logo/truconnect-logo-double-line.svg';
const checkMark = '@/assets/icons/check_mark.svg';

const visaCard = '/icons/cards-icons/visa-dark-copy.svg';
const masterCardDark = '/icons/cards-icons/master-card-dark-copy.svg';
const masterCard = '/icons/cards-icons/maestro-dark-copy-2.svg';

const poweredByStripe = '/icons/Powered by Stripe.svg';

const iconSettings = '/icons/icon-icon-settings.svg';
const iconLogout = '/icons/icon-logout.svg';
const iconUser = '/icons/user.svg';

const iconArrowDown = '/icons/arrow-down-sign-to-navigate.svg';

const iconCheck = '/assets/icons/icon-icon-check.svg';

const appleStore = '/images/googlePlay_and_AppStore/app-store.svg';
const googlePlay = '/images/googlePlay_and_AppStore/google-play-badge.svg';
const huawei = '/images/googlePlay_and_AppStore/Huawei-dark.svg';

const appleStoreWhite = '/images/googlePlay_and_AppStore/app-store-white.svg';
const googlePlayWhite = '/images/googlePlay_and_AppStore/google-play-white.svg';
const huaweiWhite = '/images/googlePlay_and_AppStore/Huawei-light.svg';

const featured_at_jack = '/images/featured_at_jack.png';
const image_roll = '/images/backgrounds/image-roll.svg';
const featured_black_desktop = '/icons/feature-black-desktop.svg';
const featured_black_mobile = '/icons/feature-black-mobile.svg';
const punch_border = '/icons/punch-border.svg';

const stripeSecureIcon = '/icons/stripe-secure-icon.svg';

const brand_logo_side_white = '/images/logo/brand-logo-side-white.svg';

const apple_pay = '/icons/apple_pay.svg';
const paypal_pay = '/icons/paypal_pay.svg';

const cardIcon = '/icons/card.svg';

const cardsmonth = '/icons/cardsmonth.svg';

const cardsinfo = '/icons/cardsinfo.svg';

const punch_border_left = '/images/punch_border_left.png';

const punch_border_top = '/images/TruConnect_Punch_Border_Transparent 2.png';
const punch_border_bottom = '/images/punch_bottom.svg';

const social_facebook = '/icons/social/facebook.svg';
const social_instagram = '/icons/social/instagram.svg';
const social_twitter = '/icons/social/twitter.svg';

const get_the_app_app_store = '/icons/get-the-app/app-store.svg';
const get_the_app_google_play = '/icons/get-the-app/google-play.svg';

const arrow_scroll_down = '/icons/arrow-scroll-down.svg';

const arrow_customer_review = '/icons/arrow-customer-review.svg';

const radio_checked_icon = 'assets/icons/RadioCheckedIcon.svg';
const radio_unchecked_icon = 'assets/icons/RadioUncheckedIcon.svg';

const payment_checkbox_checked = '/payment/icons/PaymentCheckboxChecked.svg';
const payment_input_toggle_chevron = '/assets/icons/PaymentInputToggleChevron.svg';

const payment_credit_card = 'assets/icons/PaymentCreditCard.svg';
const payment_pay_pal = 'assets/icons/PaymentPayPal.svg';

export type ImageName =
  | 'punch_border_top'
  | 'punch_border_bottom'
  | 'punch_border_left'
  | 'apple_pay'
  | 'cardsmonth'
  | 'paypal_pay'
  | 'brand_logo_side_white'
  | 'appleStoreWhite'
  | 'googlePlayWhite'
  | 'huaweiWhite'
  | 'appleStore'
  | 'googlePlay'
  | 'huawei'
  | 'bigLogo'
  | 'facebook'
  | 'google'
  | 'apple'
  | 'logo'
  | 'blackLogo'
  | 'truconnect-heart'
  | 'truconnect-rectangle'
  | 'visa-card'
  | 'master-card-dark'
  | 'master-card'
  | 'check-icon'
  | 'settings'
  | 'logout'
  | 'user'
  | 'arrow-white-down'
  | 'stripe-secure-icon'
  | 'checkMark'
  | 'featured_at_jack'
  | 'image_roll'
  | 'featured_black_desktop'
  | 'featured_black_mobile'
  | 'punch_border'
  | 'poweredByStripe'
  | 'cardIcon'
  | 'cardsinfo'
  | 'social_facebook'
  | 'social_instagram'
  | 'social_twitter'
  | 'get_the_app_app_store'
  | 'get_the_app_google_play'
  | 'arrow_scroll_down'
  | 'arrow_customer_review'
  | 'radio_checked_icon'
  | 'radio_unchecked_icon'
  | 'payment_checkbox_checked'
  | 'payment_input_toggle_chevron'
  | 'payment_credit_card'
  | 'payment_pay_pal';

const Images: Record<ImageName, string> = {
  punch_border_bottom,
  punch_border_top,
  appleStoreWhite,
  cardIcon,
  cardsinfo,
  cardsmonth,
  googlePlayWhite,
  huaweiWhite,
  appleStore,
  image_roll,
  googlePlay,
  poweredByStripe,
  huawei,
  apple_pay,
  paypal_pay,
  featured_at_jack,
  bigLogo: truconnectBy,
  checkMark,
  facebook: facebookIcon,
  google: googleIcon,
  apple: appleIcon,
  logo: logoSource,
  blackLogo: blackLogo,
  'truconnect-heart': truconnectHeartIcon,
  'truconnect-rectangle': truconnectRectangleIcon,
  'visa-card': visaCard,
  'master-card-dark': masterCardDark,
  'master-card': masterCard,
  'check-icon': iconCheck,
  settings: iconSettings,
  logout: iconLogout,
  user: iconUser,
  brand_logo_side_white,
  'arrow-white-down': iconArrowDown,
  'stripe-secure-icon': stripeSecureIcon,
  featured_black_desktop: featured_black_desktop,
  featured_black_mobile: featured_black_mobile,
  punch_border: punch_border,
  punch_border_left,
  social_facebook,
  social_instagram,
  social_twitter,
  get_the_app_app_store,
  get_the_app_google_play,
  arrow_scroll_down,
  arrow_customer_review,
  radio_checked_icon,
  radio_unchecked_icon,
  payment_checkbox_checked,
  payment_input_toggle_chevron,
  payment_credit_card,
  payment_pay_pal,
};

export default function IconsComponent({
  name,
  className,
  nextImage = false,
}: {
  name: ImageName;
  className?: string;
  nextImage?: boolean;
}) {
  const imageSource = Images[name] ?? null;
  if (!imageSource) return null;
  if (nextImage) {
    return <Image src={imageSource} alt={'name'} className={className} layout={'fill'} />;
  }
  return <img src={imageSource} alt={name} className={className} />;
}
