import classNames from 'classnames';

interface SectionHeaderProps {
  children: string;
  className?: string;
}

const SectionHeader = ({ children, className = '' }: SectionHeaderProps) => (
  <div className={classNames('font-stag text-4xl font-bold text-neutrals-white', className)}>
    {children}
  </div>
);

export default SectionHeader;
