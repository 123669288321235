import classNames from 'classnames';
import { IWorkout } from '@/types/index';

interface ProgressBarProps {
  workout: IWorkout | { progress: number; duration: number };
  withPercentageTooltip?: boolean;
}

const ProgressBar = ({ workout, withPercentageTooltip }: ProgressBarProps) => {
  const percentage = Math.floor((workout?.progress * 100) / workout?.duration);

  return (
    <div className="relative h-1 w-full rounded">
      <div className="absolute left-0 right-0 top-0 bottom-0 rounded bg-neutrals-gray" />
      <div
        style={{
          width: `${percentage > 80 ? '100' : percentage}%`,
        }}
        className={classNames('absolute left-0 right-0 top-0 bottom-0 rounded bg-brand-primary')}
      />
      {withPercentageTooltip && (
        <div className="absolute top-2 right-0">
          <div
            className={
              'mx-auto h-0 w-0 border-x-[3px] border-b-[3px] border-x-transparent border-b-brand-primary'
            }
          />
          <span
            className={'flex items-center justify-center rounded bg-brand-primary px-1 text-xs'}
          >
            {percentage > 80 ? '100' : percentage}%
          </span>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
