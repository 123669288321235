import UserContext from '@/context/UserContext';

import useAuth from '@/hooks/useAuth';

const UserProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const contextValue = useAuth();

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export default UserProvider;
