import Head from 'next/head';

export const CustomHead = () => (
  <Head>
    {/* <!-- HTML Meta Tags --> */}
    <title>Rushfit - Get Fit Fighting with Georges St-Pierre</title>
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <link rel="icon" href="/favicon.ico" />
    <link rel="shortcut icon" href="/favicon.ico" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />

    {/* <!-- Google / Search Engine Tags --> */}
    <meta itemProp="name" content="Rushfit" />
  </Head>
);
