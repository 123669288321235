export const PAGE = {
  LANDING: '/',
  PROGRAM_DETAILS: '/program-details',
  RUSHFIT: '/rushfit',
  RUSHFIT_TWO: '/rushfit-two',
  STRIKE: '/strike',
  BONUS_SESSIONS: '/bonus-sessions',

  PRIVACY_POLICY: '/privacy-policy',
  TERMS: '/terms',
  CONTACT_AND_SUPPORT: '/contact-and-support',
  COOKIE_POLICY: '/cookie-policy',

  OPTIONS_SIGNIN: '/auth/options/signin',
  OPTIONS_SIGNUP: '/auth/options/signup',
  WELCOME: '/auth/welcome',

  UPDATE_PASSWORD: '/update-password/',
  RESET_PASSWORD: '/reset-password/',
  MAGIC_LINK_LOGIN: '/ml-login',

  HOME: '/homepage',
  SETTINGS: '/settings',
  PROGRAMS: '/programs',
  WORKOUTS: 'workouts',
};
