import React, { SVGProps } from 'react';

export const SvgCircleArrowDown = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_33053_24572)">
      <path
        d="M12.5.5a12 12 0 1012 12 12.013 12.013 0 00-12-12zm5.707 14.535l-3.586 3.586a3 3 0 01-4.243 0l-3.585-3.586-.024-.025a1 1 0 111.438-1.389l3.293 3.293L11.493 6.5a1 1 0 012 0l.007 10.413 3.293-3.292a1 1 0 111.414 1.414z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="clip0_33053_24572">
        <path fill="#fff" transform="translate(.5 .5)" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
);
