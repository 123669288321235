import classNames from 'classnames';

const SettingsSubheading = ({
  children,
  className = '',
}: {
  children: string;
  className?: string;
}) => <div className={classNames('mb-4 text-base font-extrabold', className)}>{children}</div>;

export default SettingsSubheading;
