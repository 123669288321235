import { SvgRadioChecked, SvgRadioUnchecked } from '@/components/svg';

interface PaymentRadioProps {
  disabled: boolean;
  children: JSX.Element | JSX.Element[];
  value: any;
  onToggle: () => void;
}

const PaymentRadio = ({ value, onToggle, children, disabled = false }: PaymentRadioProps) => (
  <div className="flex flex-row space-x-4">
    <button type="button" onClick={onToggle} disabled={disabled} className="w-7 h-7">
      {value ? <SvgRadioChecked /> : <SvgRadioUnchecked />}
    </button>
    {children}
  </div>
);

export default PaymentRadio;
