import { DispatchWithoutAction } from 'react';
import classNames from 'classnames';

import { SvgArrow } from '@/components/svg';
import { ButtonType } from '@/types/components/button.type';

interface ExpandButtonProps {
  buttonType: ButtonType;
  isOpen: boolean;
  toggleOpen: DispatchWithoutAction;
}

const ExpandButton = ({
  buttonType = ButtonType.SECONDARY,
  isOpen,
  toggleOpen,
}: ExpandButtonProps) => (
  <button
    className={classNames(
      'flex cursor-pointer items-center gap-2 text-lg',
      buttonType === ButtonType.PRIMARY && 'primary-expand-button'
    )}
    onClick={toggleOpen}
  >
    <span className="text-lg font-extrabold uppercase leading-9">
      {!isOpen ? 'READ MORE' : 'SHOW LESS'}
    </span>
    <SvgArrow
      className={classNames(
        'h-8 w-8 transition-all duration-500',
        isOpen ? 'rotate-180' : 'rotate-0'
      )}
    />
  </button>
);

export default ExpandButton;
