export const HOME_HEADER_TYPES = {
  program: 'program',
  workout: 'workout',
  link: 'link',
} as const;

export default interface IHomeHeader {
  id: number;
  image: string;
  title: string;
  subtitle: string;
  type: keyof typeof HOME_HEADER_TYPES;
  data: {
    [x: string]: string;
  };
}
