import { SVGProps } from 'react';

const SvgCreditCard = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="21"
    viewBox="0 0 29 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.1 0.130707H2.9C1.2905 0.130707 0.0145 1.23996 0.0145 2.62341L0 17.5796C0 18.963 1.2905 20.0723 2.9 20.0723H26.1C27.7095 20.0723 29 18.963 29 17.5796V2.62341C29 1.23996 27.7095 0.130707 26.1 0.130707ZM24.65 17.5796H4.35C3.5525 17.5796 2.9 17.0187 2.9 16.3333V10.1015H26.1V16.3333C26.1 17.0187 25.4475 17.5796 24.65 17.5796ZM26.1 5.1161H2.9V3.86976C2.9 3.18426 3.5525 2.62341 4.35 2.62341H24.65C25.4475 2.62341 26.1 3.18426 26.1 3.86976V5.1161Z"
      fill="white"
    />
  </svg>
);

export default SvgCreditCard;
