import Link from 'next/link';
import { PAGE } from '@/router/routes';
import IHomeHeader from '@/types/HomeHeader';
import { HOME_HEADER_TYPES } from '@/types/HomeHeader';

const SmartLink = ({
  homeHeader,
  children,
}: {
  homeHeader: IHomeHeader | null;
  children: React.ReactNode;
}) => {
  if (homeHeader?.type === HOME_HEADER_TYPES.program) {
    return <Link href={`${PAGE.PROGRAMS}/${homeHeader.data.id}`}>{children}</Link>;
  }
  if (homeHeader?.type === HOME_HEADER_TYPES.workout) {
    return (
      <Link
        href={`${PAGE.PROGRAMS}/${homeHeader.data.programme_id}/${PAGE.WORKOUTS}/${homeHeader.data.workout_id}`}
      >
        {children}
      </Link>
    );
  }

  return (
    <a href={homeHeader?.data.url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default SmartLink;
