import { SVGProps } from 'react';

const SvgPayPal = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_26512_67476)">
      <path
        d="M22.0001 9.761C22.0001 10.297 21.9351 10.845 21.8311 11.388C20.9841 15.807 18.0851 17.334 14.3821 17.334H13.8101C13.3571 17.334 12.9721 17.668 12.9021 18.123L12.0991 23.213C12.0281 23.666 11.6431 24 11.1911 24H8.45507C8.06507 24 7.76707 23.652 7.82707 23.268L9.21307 14.388L9.27507 14.332H11.4301C16.6651 14.332 19.9391 11.714 20.9031 6.764C21.7151 7.578 22.0001 8.64 22.0001 9.761ZM7.78407 14.013C7.90007 13.187 8.24307 12.836 9.16907 12.834L11.4291 12.832C16.0031 12.832 18.6271 10.742 19.4521 6.442C20.2521 2.308 17.3501 0 13.4211 0H6.07707C5.56007 0 5.11907 0.382 5.03907 0.901C2.73507 15.736 2.06907 19.508 2.00107 20.659C1.98007 21.021 2.27007 21.331 2.63607 21.331H6.62507L7.78407 14.013Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_26512_67476">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPayPal;
