import { SVGProps } from 'react';

const SvgPlayIcon = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#fff" />
    <path d="M17 28l10-7.5L17 13v15z" fill="#1A1919" />
  </svg>
);

export default SvgPlayIcon;
