import { ReactNode } from 'react';
import classNames from 'classnames';
import { Loader } from '@/components/shared';

interface IProcess {
  className?: string;
  condition: boolean;
  children?: ReactNode;
  loader?: boolean;
}

const Process = ({ className, condition, children, loader = true }: IProcess) =>
  condition ? (
    <div className={classNames('flex items-center justify-center', className)}>
      {loader && <Loader color="#FFFFFF" size={24} />}
    </div>
  ) : (
    <div className="flex items-center justify-center">{children}</div>
  );

export default Process;
