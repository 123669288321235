import { createContext } from 'react';
import IUser from "@/types/User";

interface IUserContext {
  user: IUser | null;
  isFetching: boolean;
  setUser : (user : IUser | null) => void;
  logOut : () => void;
}

const userContext = createContext<IUserContext>({
  user: null,
  isFetching: false,
  setUser: (user : IUser | null) => {},
  logOut : () => {}
});

export default userContext;
